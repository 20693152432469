// import 'babel-polyfill';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
// require('es6-promise').polyfill();

var environment = true; //true 生产环境   false 开发环境

import httpRequest from '@/util/http.js';
//
//公共组件
import vcomponents from "@/components";
//工具
import Cookies from 'js-cookie';

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';


import {
  Loading
} from 'element-ui';
import html2canvas from "html2canvas";

/* 使用swiper插件 */
import VueAwesomeSwiper from 'vue-awesome-swiper';
// import "swiper/swiper-bundle.css";
Vue.use(VueAwesomeSwiper);
Vue.use(ElementUI);

/**
 * 截图插件
 */
import VueCropper from 'vue-cropper';
Vue.use(VueCropper);

//公共样式
require('../public/base.css');

Vue.config.productionTip = false;
Vue.use(vcomponents);
Vue.prototype.$cookie = Cookies;
Vue.prototype.Loading = Loading;
let loading = null;

Vue.prototype.LoadingOpen = () => {
  loading = Loading.service({
    text: '请稍后'
  });
};
Vue.prototype.LoadingClose = () => {
  loading.$nextTick(() => {
    // 以服务的方式调用的 Loading 需要异步关闭
    loading.close();
  });
};

Vue.prototype.$http = httpRequest; // ajax请求方法
Vue.prototype.canvas = html2canvas;

// 兼容不同的客户
import Compatible from './util/compatible.js';
let VueCompatible = new Compatible().init(environment);
Vue.prototype.Compatible = VueCompatible;
const phone = VueCompatible.webCore.phone;
const pc = VueCompatible.webCore.pc;
console.log(pc);
new Vue({
  store,
  router,
  render: h => h(App),
  created() {
    // console.log(environment);
    if (environment) { //生产环境
      if (window.location.href.indexOf('easysmile.magicalign.com') != -1) { // 轻松笑
        this.$http.defaults.baseURL = 'http://easysmile.magicalign.com/web'
        this.webservice = 'http://easysmile.magicalign.com'
        // console.log('轻松笑')
      } else if (window.location.href.indexOf('vvsmile.magicalign.com') != -1 || window.location.href.indexOf('plusbeauty.magicalign.com') != -1) { //vvsmile
        this.$http.defaults.baseURL = 'http://vvsmile.magicalign.com/web'
        this.webservice = 'http://vvsmile.magicalign.com'
        // console.log('vvsmile')
      } 
      else if (window.location.href.indexOf('jiegeer.magicalign.com') != -1) {
        //jiege
        // 切换跳转地址
        this.$http.defaults.baseURL = 'http://jiegeer.magicalign.com/web';
        this.webservice = 'http://jiegeer.magicalign.com/wap/';


      } else if (window.location.href.indexOf('smiletrack.magicalign.com') != -1) {
        this.$http.defaults.baseURL = 'http://smiletrack.magicalign.com/web'
        this.webservice = 'http://smiletrack.magicalign.com/wap/';



      } else if (window.location.href.indexOf('cherrysmile.magicalign.com') != -1) {

        this.$http.defaults.baseURL = 'http://cherrysmile.magicalign.com/web';
        this.webservice = 'http://cherrysmile.magicalign.com/wap/';
      }
      else if (window.location.href.indexOf('moresmile.magicalign.com') != -1) {

        this.$http.defaults.baseURL = 'http://moresmile.magicalign.com/web';
        this.webservice = 'http://moresmile.magicalign.com/wap/';
      }
      else if (window.location.href.indexOf('yae920.magicalign.com') != -1) {
        this.$http.defaults.baseURL = 'https://yae920.magicalign.com:8443/web'
        this.webservice = 'http://yae920.magicalign.com/wap/'
      }
      // else if (window.location.href.indexOf('http://172.168.0.140:8080') != -1) {
      //   this.$http.defaults.baseURL = 'http://jiegeer.magicalign.com/web';
      //   this.webservice = 'http://jiegeer.magicalign.com/wap/';
      // }  
      // else if (window.location.href.indexOf('http://172.168.0.140:8080') != -1) {
      //   //jiege
      //   // 切换跳转地址
      //   this.$http.defaults.baseURL = 'http://jiegeer.magicalign.com/web';
      //   this.webservice = 'http://jiegeer.magicalign.com/wap/';


      // }
      else { // 正丽
        // this.$http.defaults.baseURL = 'http://172.168.0.130:8090/web'; //调本地修改这个
           this.$http.defaults.baseURL = 'https://yae920.magicalign.com:8443/web'
        this.webservice = 'https://yae920.magicalign.com/wap/'
        // this.$http.defaults.baseURL = 'http://case.magicalign.com/web'
        // this.webservice = 'http://case.magicalign.com'
        // console.log('正丽')
      }
    } else {
      this.$http.defaults.baseURL = 'http://case.magicalign.com:9999/web' //开发环境  
      // this.$http.defaults.baseURL = 'http://yae920.magicalign.com:9999/web' 
      // this.$http.defaults.baseURL = 'http://case.magicalign.com/web' //开发环境   
      // this.$http.defaults.baseURL = 'http://180.169.239.242:5000/web' //开发环境 
      // this.$http.defaults.baseURL = 'http://114.80.126.216:5000/web'; //测试环境
      //  this.$http.defaults.baseURL = 'http://172.168.0.130:8090/web'; 


      //  this.$http.defaults.baseURL = 'http://172.168.0.105:8888/web'
      //  this.$http.defaults.baseURL = 'http://121.40.215.206:9999/web'
      // this.$http.defaults.baseURL = 'http://172.168.0.146:8090/web' //临时测试
    }

    console.log(localStorage.getItem('model'));
    if (localStorage.getItem('model') == null) {

      if (location.href.indexOf('smiletrack.magicalign.com') != -1) {
        localStorage.setItem('model', false)
      } else {
        localStorage.setItem('model', true)


      }
    }
    console.log(localStorage.getItem('model'));

    this.$store.commit('changeMode', localStorage.getItem('model') == 'true' ? true : false)

  },
  beforeCreate: () => {
    //加载页面时执行一次
    changeMargin();
    //监听浏览器宽度的改变
    window.onresize = function () {
      changeMargin();
    };

    function changeMargin() {
      /* 计算html的字体大小 开始 */
      var html = document.documentElement;
      if (window.innerWidth == 1360) {
        html.style.fontSize = '70px';
      } else if (window.innerWidth > 1360) {
        html.style.fontSize = (window.innerWidth - 1360) / 20 + 70 + 'px';
      } else {
        html.style.fontSize = 70 + (window.innerWidth - 1360) / 20 + 'px';
      }

      if (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i) || window.screen.width < 1060) {
        window.location.replace(phone);
      } else { }
    }
  }
}).$mount('#app');

export default {
  pc
};